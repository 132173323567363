<template>
  <v-container>
    <backArrow
      style="margin-left: 12px; margin-top: 5px; position: initial"
      :action="() => onClickBackArrow()"
    />
    <v-row class="px-3 py-3" style="height: 93vh;">
      <v-col cols="12" align-self="start">
        <v-row>
          <v-col cols="12">
            <p class="main-title mb-0 pb-0">Telemedicina</p>
            <p class="mt-2 font-weight-bold" style="color: var(--v-primary)">
              Insira os dados do seu cartão
            </p>
          </v-col>

          <v-col cols="12" class="pt-0 mb-5">
            <v-row class="mx-0 px-0">
              <v-img v-for="cardCredit in cardCredits" :key="cardCredit.name" :aspect-ratio="16/9" :src="'/img/icons/credit-card/' + cardCredit.img"></v-img>
            </v-row>
          </v-col>


          <v-col col="12">
            <v-sheet rounded="lg" style="background-color: rgba(255, 255, 255, .5) !important;">
              <v-form ref="formPayment" lazy-validation>
                <v-row class="px-3">
                  <v-col cols="12" class="mb-0 pb-0">
                    <v-text-field
                        placeholder="Número do cartão"
                        label="Número do cartão*"
                        v-model="card.number"
                        v-mask="'#### #### #### ####'"
                        :rules="[formrules.creditCardRequired]"
                    />
                    <v-text-field
                        placeholder="Nome impresso no cartão"
                        label="Nome impresso no cartão*"
                        v-model="card.personName"
                        :rules="[formrules.required]"
                    />
                  </v-col>

                  <v-col cols="6" class="py-0 my-0">
                    <v-text-field
                        placeholder="mm/aa"
                        label="Vencimento*"
                        v-model="card.endDate"
                        v-mask="'##/##'"
                        :rules="[formrules.creditCardEndDate]"
                    />
                  </v-col>

                  <v-col cols="6" class="py-0 my-0">
                    <v-text-field
                        placeholder="xxx"
                        label="Código de segurança*"
                        v-model="card.securityCode"
                        v-mask="'###'"
                        :rules="[formrules.creditCardSecurityCodeRequired]"
                    />
                  </v-col>

                  <v-col cols="11" class="pt-0 mt-0 pr-0 mr-0">
                    <google-map-autocomplete
                        cols="12"
                        class="mb-6 pb-6"
                        @input="upateAddress"
                        :loadSearch="googleSearch"
                        placeholder="Confirmar o endereço de cobrança*"
                        label="Confirmar o endereço de cobrança*"
                    />
                  </v-col>
                  <v-col cols="1" class="pt-0 mt-0 px-0 mx-0">
                    <v-checkbox v-model="confirmAddress"></v-checkbox>
                  </v-col>
                </v-row>
              </v-form>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <p class="font-weight-bold text-truncate">Valor total mensal..............</p>
          </v-col>

          <v-col cols="4">
            <p class="font-weight-bold text-truncate text-right">R$ {{ totalCost }},00</p>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="px-3" align-self="end">
        <p style="margin-top: 2%;" class="text-caption main-text">*Campos obrigatórios</p>
        <generic-btn
            style="width: 100%"
            :btn-props="{ disabled: disabledConfirmPayment }"
            :on-click="() => !this.changeCreditCard? confirmPayment() : updateCreditCard()"
        >
          {{ !this.changeCreditCard ? "Confirmar Compra" : "Confirmar" }}
        </generic-btn>
      </v-col>
    </v-row>

    <v-card elevation="16" v-if="addLocal" class="address-card">
      <v-container class="pa-10 pt-6">
        <v-row class="mb-0">
          <v-col class="pt-0" cols="12">
            <p style="font-size: 20px" class="main-strong-text mb-0 pt-2">
              Local
            </p>
          </v-col>
        </v-row>

        <v-text-field
            label="CEP"
            v-model="local.cep"
            :rules="[rules.validCEP]"
            v-mask="'#####-###'"
        />

        <v-text-field label="Rua" v-model="local.address" />
        <v-text-field
            label="Nº"
            :rules="[rules.limitNumber]"
            v-model="local.number"
        />
        <v-text-field label="Bairro" v-model="local.neighbour" />
        <v-text-field label="Cidade" v-model="local.city" />
        <v-text-field label="Estado" v-model="local.state" disabled />
        <generic-btn
            style="width: 100%; margin-top: 5%"
            :btn-props="{ disabled: disableLocalForm }"
            :on-click="saveAddress"
        >
          Salvar
        </generic-btn>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import backArrow from "../../components/covid/back-arrow";
import { mapMutations } from "vuex";
import { formrules } from "./../../common/formrules";
import { regexValidations } from "@/common/constants";
import GoogleMapAutocomplete from "../../components/google-maps/google-map-autocomplete";
import AddressService from "@/services/contract/AddressService";
import ContractService from "@/services/contract/ContractService";
import PaymentService from "@/services/payment/PaymentService";

export default {
  name: "PaymentManagement",
  components: {
    GoogleMapAutocomplete,
    backArrow,
  },
  data: () => ({
    formrules,
    totalCost: 0,
    cartDependents: [],
    dependentsToRegister: [],
    hasSavedAddress: false,
    changeCreditCard: false,
    subscriptionId: null,
    cardCredits: [
      { name: 'visa1', img: 'credit_card_img_visa.png'},
      { name: 'visa2', img: 'credit_card_img_master_card.png'},
      { name: 'visa3', img: 'credit_card_img_elo.png'},
      { name: 'visa4', img: 'credit_card_img_hiper_card.png'},
      { name: 'visa5', img: 'credit_card_img_american_express.png'}
    ],
    card: {
      endDate: null,
      number: null,
      personName: null,
      securityCode: null,
    },
    local: {
      cep: null,
      neighbour: "",
      address: "",
      number: "",
      city: "",
      state: "",
      country: "",
      complement: ""
    },
    confirmAddress: false,
    rules: {
      limitNumber: (value) => value && value.length <= 10 || 'Máximo 10 caracteres',
      validCEP: (value) => value && value.length === 9 || 'CEP inválido',
    },
    addLocal: false,
    googleSearch: '',
  }),
  computed: {
    disableLocalForm() {
      return (!this.local.address || !this.local.number || (this.local.cep && !this.local.cep.match(regexValidations.cep)));
    },
    disabledConfirmPayment(){
      return this.formrules.creditCardSecurityCodeRequired(this.card.securityCode) !== true
          || this.formrules.creditCardRequired(this.card.number) !== true
          || this.formrules.required(this.card.personName) !== true
          || this.formrules.creditCardEndDate(this.card.endDate) !== true
          || !this.googleSearch || !this.googleSearch.length
          || !this.confirmAddress || !this.hasSavedAddress;
    }
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    async confirmPayment() {
      if(!this.$refs.formPayment.validate()) {
        setTimeout(() => {
          this.$refs.formPayment.resetValidation();
        }, 3000);
        return;
      }

      if(!this.confirmAddress) {
        this.showmsg({text: "Por favor, selecione o campo de confirmação de endereço.", type: "error"});
        return;
      }

      let values = this.card.endDate.replace("/", "-").split("-")
      const payload = {
        card: {
          number: this.card.number,
          expiresAt: "20" + values[1] + "-" + values[0] ,
          holder: this.card.personName,
          cvv: this.card.securityCode
        },
        address: {
          zipCode: this.local.cep.replace("-", ""),
          street: this.local.address,
          number: this.local.number,
          city: this.local.city,
          state: this.local.state,
          neighborhood: this.local.neighbour,
          complement: this.local.complement,
        },
        dependents: this.dependentsToRegister
      }

      this.loading(true);
      await this.createTelemedicineDependentAndReloadSession(payload)
            .then((response) => {
              this.$router.push({name: 'telemedicinePaymentStatus', query: { subscriptionId: response.data } });
            })
            .catch((error) => {
              if (error && error.response && error.response.data && error.response.data.message.includes("Não é permitido adicionar dependentes a adesão")) {
                this.showmsg({text: "Não foi possível adicionar o(s) dependente(s) na adesão do titular.", type: "error"});
              } else if (error && error.response && error.response.data && error.response.status === 409) {
                this.errorDialog = true;
              } else {
                this.showmsg({text: "Ocorreu um erro ao tentar registrar o dependente.", type: "error"});
              }
            })
            .finally(() => {
              this.loading(false);
            });
    },
    async updateCreditCard(){
      let values = this.card.endDate.replace("/", "-").split("-")
      let payload = {
        mainPaymentMethodId: 'creditcard',
        PaymentMethodCreditCard: {
          Card: {
            number: this.card.number,
            expiresAt: "20" + values[1] + "-" + values[0] ,
            holder: this.card.personName,
            cvv: this.card.securityCode
          }
        }
      }
      this.loading(true)
      await this.paymentService.updatePayment(this.subscriptionId, payload).then((response) => {
        this.$router.push({name: "my-payments"});
        this.showmsg({text: "Dados de pagamento atualizados com sucesso", type: "success"});
      }).catch(() => {
          this.showmsg({text: "Erro ao atualizar dados de pagamento", type: "error"});
      }).finally(() => {
        this.loading(false)
      });
    },
    async createTelemedicineDependentAndReloadSession(data){
      const beneficiaryId = this.$auth.user().beneficiaries[0].beneficiaryId
      return await this.contractService.CreateTelemedicineDependentFromHolder(beneficiaryId, data);
    },
    onClickBackArrow() {
      if(this.changeCreditCard)
        this.$router.push({name: "my-payments"});
      else
        this.$router.push({name: "telemedicineCartManagement", params: { dependents: this.cartDependents } });
    },
    saveAddress() {
      this.addLocal = false;
      this.hasSavedAddress = true;
    },
    async loadActiveAddressByCpf(cpf) {
      if(cpf) {
        await this.addressService.FindActiveAddressByCpf(cpf)
            .then(result => {
              if(result && result.data) {
                const data = {
                  code: result.data.addressZip,
                  log: result.data.address,
                  neighborhood: result.data.addressNeighborhood,
                  number: result.data.addressNumber,
                  city: result.data.addressCity,
                  country: result.data.addressCountry,
                  region: result.data.addressState,
                  complement: result.data.addressComplement
                }
                this.upateAddress(data, false);
              }
            })
            .catch(() => {
              this.loading(false);
            })
      }
    },
    upateAddress(data, showAddLocal = true) {
      const { code, neighborhood, region, city, log, number, country, complement, state } = data;

      this.hasSavedAddress = false;
      this.googleSearch = '';
      this.local.cep = code;
      this.local.neighbour = neighborhood;
      this.local.address = log;
      this.local.city = city;
      this.local.state = state? state : region;
      this.local.number = number;
      this.local.country = country;
      this.local.complement = complement;
      this.addLocal = showAddLocal;

      this.loadGoogleSearchField(data);
    },
    loadGoogleSearchField(data) {
      if(data) {
        if(data.log) {
          this.googleSearch = this.googleSearch + ' ' + data.log
        }
        if(data.number) {
          this.googleSearch = this.googleSearch + ', ' + data.number
        }
        if(data.neighborhood) {
          this.googleSearch = this.googleSearch + ' - ' + data.neighborhood
        }
        if(data.city) {
          this.googleSearch = this.googleSearch + ', ' + data.city
        }
        if(data.region) {
          this.googleSearch = this.googleSearch + ' - ' + data.region
        }
        if(data.country) {
          this.googleSearch = this.googleSearch + ', ' + data.country
        }

        this.googleSearch = this.googleSearch.trim();
      }
    },
    redirectToConnectCompany() {
      this.$router.push({ name: 'conectarComSuaEmpresa' });
    }
  },
  async mounted() {
    this.addressService = new AddressService();
    this.contractService = new ContractService();
    this.paymentService = new PaymentService();

    this.loading(true);
    if(this.$route.params && this.$route.params.dependentsToRegister) {
      this.dependentsToRegister = this.$route.params.dependentsToRegister
    }

    if(this.$route.params && this.$route.params.cartDependents) {
      this.cartDependents = this.$route.params.cartDependents;
    }

    if(this.$route.params && this.$route.params.totalCost) {
      this.totalCost = this.$route.params.totalCost;
    }

    if(this.$route.params && this.$route.params.changeCreditCard) {
      this.changeCreditCard = this.$route.params.changeCreditCard;
    }

    if(this.$route.params && this.$route.params.subscriptionId) {
      this.subscriptionId = this.$route.params.subscriptionId;
    }

    if((!this.dependentsToRegister.length || !this.totalCost || (this.totalCost && parseInt(this.totalCost)) <= 0) && !this.changeCreditCard) {
      this.redirectToConnectCompany();
    }

    if(this.$auth.user() && this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length) {
      const cpf = this.$auth.user().beneficiaries[0].personCPF;
      await this.loadActiveAddressByCpf(cpf);
    } else {
      this.redirectToConnectCompany();
    }
    this.loading(false);
  }
};
</script>

<style scoped>
.address-card {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 40px !important;
  width: 100vw;
  height: 90%;
  position: fixed;
  top: 15%;
  left: 0;
  bottom: 0;
  z-index: 20;
  -webkit-animation: medical-speciality 0.5s
  cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: medical-speciality 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
</style>
