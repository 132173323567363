/* eslint-disable */
import HttpService from '../HttpService'

export default class PaymentService {
    constructor() {
        this._httpService = new HttpService('payment');
    }

    async updatePayment(id, body) {
        return await this._httpService.put(`subscription/${id}`, body);
    }

}
