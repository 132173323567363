/* eslint-disable */
import HttpService from '../HttpService';
export default class AddressService {
  constructor() {
    this._httpService = new HttpService(`contract/address`);
  }

  async CreateAndUpdateAddress(data) {
    return await this._httpService.post('', data);
  }

  async FindActiveAddressByCpf(cpf) {
    return await this._httpService.get(`/active?cpf=${cpf}`);
  }

 };
